.ac-temperature {
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
}

.energy-saving-label {
  opacity: 0.8;
  position: absolute;
  top: 10px;
  left: 63px;
  background-color: #4caf50;
  padding: 12px;
  border-radius: 2px;
  transform: scale(0.22);
  transform-origin: left top;

  &_bg {
    padding: 10px;
    width: 200px;
    border-radius: 15px;
    background-color: #fafafa;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &_title {
    font-size: 20px;
    display: block;
    margin: 2px auto;
  }

  &_description {
    font-size: 12px;
  }
}

.ximalaya-text-link {
  color: #63a5ef;
  text-decoration: none;
  cursor: pointer;
}

.ximalaya-logo {
  margin: 12px;
  width: 56px;
}
