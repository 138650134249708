html,
body,
div,
img,
a {
  box-sizing: border-box;
}

a {
  line-height: 1.5;
}

body {
  margin: 0;
  min-height: 90vh;
  background-color: transparent;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #121212;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.font-digit {
  font-family: "Digital-7 Mono";
}

.text-center {
  text-align: center;
}
